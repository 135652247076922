.QuickReorder-modal, .mobile-QuickReorder-modal {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    overflow-y:scroll;

    .unavialble-alert{
        display: flex;
        align-items: center;
        .alert-text{
                font-family: $HelveticaNeue;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 22px;
                text-transform: capitalize;
                color: $Red-Orange;
            }
        }

    .error-container{
        background-color: $Red-Orange5;
        position: relative;
        height:auto;
        padding: 4% 6%;
        margin: 4% 0 2% 0;

        .error-sub-container{
            align-items: center;
            display: flex;
            .error-msg{
                text-align: left;
            }
            .location-alert-icon.mr-1{
                margin-top: 3px;
                align-self: flex-start;
            }
        }
    }

    .modal-content{
        top: 5%;
        .modal-body{
            background: $white;
            padding: 0;

            .modal-footer{
                justify-content: center;
                flex-wrap: nowrap;
            }
        }
       
    }

    .reorder-order-detail{
        margin-top: 30px;
    }

    .ReorderModal{
        padding: 31.5px 50px;
    }

    .global-accordian-content{
        background: $white;
        box-shadow: none;
    }

    .global-accordian-header-container{
        padding: 0 !important;
        outline: none;
    }

    .global-accordian-content{
        box-shadow: none !important;
    }

    .reorder-header{
        display: flex;
        justify-content: center;
    }

    .change-setting-btn{
		font-weight: bold;
		cursor: pointer;
	}

    .your-order{
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
    }

    .reorder-number, .reorder-sub-heading, .payment-total, .payment-total-amount{
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
    }

    .reorder-details-heading{
        display: flex;
        margin-top:30px;
    }

    .reorder-sub-heading{
        flex-basis: 150px;
    }

    .reorder-sub-detail{
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $Dark-Gray;
        text-transform: capitalize;
    }

    .order-from-section{
        display: flex;
        flex-direction: column;
    }

    .contact-info{
        display: flex;
        justify-content: space-between;
        margin-top:10px;
        .phoneNumber{
            margin-right: 45px;
        }
    }

    .reorder-accordian{
        border-top: 2px solid $Brand-Black;
        border-bottom: 2px solid $Brand-Black;
        margin-top: 32px;
        padding: 5px;
        .global-accordian-container{
            .global-accordian-header-container{
                padding: 0 !important;
                outline: none;
            }
            .global-accordian-content{
                box-shadow: none !important;
            }
        }
    }

    .reorder-modification-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $Mashed-Potato-Gray;
        border-radius: 4px;
    
        .reorder-subheading{
            font-family: $National2Test;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: $Dark-Gray;
        }
    }

    .cancel-btn{
        width: 40%;
    }

    .horizontal-bar{
        border-top: 2px solid $Brand-Black;
    }

    .ml{
        margin-left: 10px;
    }

    .mr{
        margin-right: 10px;
    }

    .mt{
        margin-top: 19.8px !important;
    }

    .reorder-payment-details{
        display: flex;
        justify-content: space-between;
        margin-top:6px;
    }

    .payment-type{
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
        font-family:$National2Regular;
        text-transform: capitalize;
    }

    .payment-amount,.item-amount{
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        color: $Brand-Black;
    }

    .width-style{
        width: 30%;
    }

    .width-style-lg{
        width:65%
    }

    .reorder-accordian{
        .global-accordian-header, .global-accordian-arrow{
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $Brand-Black;
            font-weight: bold;
            font-family: $National2Condensed;
        }
        
        .reorder-order-item{
            margin-bottom: 8px;
        }

        .reorder-order-item, .reorder-item-details{
            display: flex;
            justify-content: space-between;
            .cart-item-price{
                display: flex;
                flex-direction: column;
                .old-price{
                    text-decoration: line-through;
                }
                .modified-price{
                    color: $Red-Orange;
                }
            }
        }

        .item-count-box{
            height: 24px;
            width: 24px;
            left: 0px;
            top: 0px;
            background: $Light-Gray;
            mix-blend-mode: multiply;
            border-radius: 4px;
            .item-count{
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 22px;
                color: $Brand-Black;
            }
        }

        .item-desc-section{
            display: flex;
            flex-direction: column;
            font-family: $National2Regular;
            font-style: normal;
        }

        .item-desc{
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $Brand-Black;
        }

        .item-desc-quantity{
            font-size: 12px;
            line-height: 22px;
            color: $Dark-Gray;
        }
    }
}


.reorder-modal, .mobile-reorder-modal{

    .modal-content{
        min-height: 344px !important;

        .modal-body{
            background: $white;
            box-shadow:none !important;
        }
        
        .modal-header{
            border-bottom: none;
    
            .custom-modal-close{
                top: 5%;
                z-index: 1;
            }
        }
    }
    
    .confirmationModal{
        padding: 35px 80px 52px 80px;
    }

    .reorder-modal-container{
        display: flex;
        justify-content: center;
    }

    .reorder-modal-btn{
        position: absolute;
        bottom: 22%;
    }

    .reorder-error-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        .content-text {
            text-align: $center;
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $Brand-Black;
            width: 80%;
    
            @media #{$breakpoint-below-tablet} {
                font-size: 20px;
                line-height: 22px;
                width: 66%;
                margin: auto;
            }
        }
        .red-text {
			color: $Red-Orange;
		}
    }

    .error-icon{
        width: 60px;
        height: 61.01px;
    }
    
    .error-msg{
        font-family: $National2Condensed;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
        width: 377px;
    }
}

.mobile-reorder-modal{
    .modal-content{
        height: 301px;
        top: 25%;
        .modal-body{
            padding: 20px;
        }
       
    }   

    .confirmationModal{
        padding: 0;
    }

    .error-msg{
        width: 335px;
        font-size: 20px;
    }

    .reorder-error-container{
        .reorder-modal-btn{
            bottom: 15%;
        }
        .closebtn-cls{
            margin-right: 5px;
        }
    }
}

.mobile-QuickReorder-modal{
    .modal-content{
        height: auto;
        @media #{$breakpoint-below-tablet} {
            height: 100% !important;
        }
    }
    .ReorderModal{
        padding: 30px 20px;
    }
    .modal-footer{
        border: none;
        button{
            padding:11px 20px;
        }
    }

    .reorder-modal-btn{
        display: flex;
        button{
            padding: 11px 20px;
        }
    }
}


.reorder-modal-global{
    .modal-body{
        box-shadow: none !important;
    }
    .modal-body:focus {
        outline: none !important;
        box-shadow: none !important;
    
    }
    .item-desc-bold{
		font-family: $National2Medium !important;	
		font-weight: bold !important;
	}

    .capitalize-text{
        text-transform: capitalize;
    }
    
    .modal-header{
        padding:1% 1% !important;
        height: 63px !important;
        @media #{$mobile-only}{
            height: 46px !important;
        }
    }

    .reorder-form-button{
        padding: 9px 40px;
    }

    .modal-footer{
        padding:1% 1% !important;
        justify-content: center;
        height: 90px;
    }

    .schedule-order-subsection-value{
        text-transform: capitalize;
    }

    .store-cannot-deliver-error{
        background-color: $Alert-Yellow;
    }
    .store-error-msg-container{
        background-color: $Red-Orange5;
    }
    .store-cannot-deliver-icon{
        width: 25px !important;
    }

    .reorder-item-section{
        margin: 3% 15%;
        @media #{$breakpoint-below-tablet} {
            margin: 3% 2%;
        }
        .reorder-payment-details {
            display: flex;
            justify-content: space-between;
            margin-top: 6px;
            .payment-total,
            .payment-total-amount {
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: $Brand-Black;
            }
        }
        .reorder-section-heading{
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $Brand-Black;
        }

        .unavailable-msg{
            margin-top: 4.18px;
            .unavailable-msg-icon{
                margin-right:8px
            }
        }

        .error-can-not-deliver{
            background-color: $Alert-Yellow;
            margin-top:10px !important;
            @media #{$breakpoint-above-tablet} {
                height:54px;
            }
            @media #{$breakpoint-below-tablet} {
                height: 10vh;;
            }
            .error-message{
                font-family: $National2;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: -0.1px;
                color: $Brand-Black;
                @media #{$breakpoint-below-tablet} {
                    padding: 0px 37px 0px 16px;
                }
            }
            img{
                @media #{$breakpoint-below-tablet} {
                    margin-top:4px !important;
                    margin-right:0px
                }
            }

        }
        .reorder-accordian {
            border-top: 2px solid $Brand-Black;
            border-bottom: 2px solid $Brand-Black;
            margin-top: 32px;
            padding: 5px;

            .global-accordian-container {
                .global-accordian-header-container {
                    padding: 0 !important;
                    outline: none;
                    height: 80px;
                    align-items: center;
                }
                .global-accordian-content{
                    box-shadow: none !important;
                }
            }

            .global-accordian-header,
            .global-accordian-arrow {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: $Brand-Black;
                font-weight: bold;
                font-family: $National2Condensed;
            }
            
            .reorder-order-item {
                margin-bottom: 8px;
            }

            .reorder-order-item,
            .reorder-item-details {
                display: flex;
                justify-content: start;
            }

            .item-count-box {
                height: 24px;
                width: 24px;
                left: 0;
                top: 0;
                background: $Light-Gray;
                mix-blend-mode: multiply;
                border-radius: 4px;
                position: relative;

                .item-count {
                    font-family: $National2Regular;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 22px;
                    color: $Brand-Black;
                    position: absolute;
                    left: 8px;
                }
            }

            .item-desc-section {
                display: flex;
                flex-direction: column;
                font-family: $National2Regular;
                font-style: normal;
                flex: 1;
            }

            .item-desc {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $Brand-Black;
                margin-left: 10px;
            }

            .item-amount{
                font-family: $National2;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                text-align: right;
                color: $Brand-Black;
            }

            .item-desc-quantity {
                font-size: 12px;
                line-height: 22px;
                color: $Dark-Gray;
            }
        }
    }
}