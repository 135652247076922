.headerBottom {
	&.au {
		.headBottomParent {
			display: flex;
			margin: auto;

			.headBottomTxt {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 20px;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				color: #fff;
				margin: auto 20px auto auto;
			}


			// .redButton {
			// 	padding: 10px 40px;
			// }
		}
	}
}

@media #{$breakpoint-below-tablet} {
	.headerBottom {
		&.au {
			box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
			& .headBottomParent {
				display: block;
				text-align: $center;
				margin: auto;

				//& .redButton {
				//	padding: 10px 40px;
				//}

				& .start-order-btn {
					margin-top: 1rem;
					width: 100%;
					margin-bottom: 21px;
				}

				& .headBottomTxt {
					font-size: 1rem;
					line-height: 20px;
					letter-spacing: 1px !important;
					margin-top: 12px;
					margin-right: unset;
					&.start-order-text {
						@media #{$breakpoint-below-tablet} {
							letter-spacing: 0.01px;
						}
					}
				}
			}
		}
	}
}

.headerTrackOrderPanel {
	&.au {
		height: 50px;
		border-top: #ffffff 0.1px solid;
		display: flex;
		background: $Brand-Black;

		.headBottomParent {
			margin: auto;
			align-self: center;
			display: flex;
			justify-content: center;

			.header-track-order-btn {
				button {
					float: left;
					border: 1px solid rgb(255, 255, 255);
					background: #202124;
					color: #fff;
					font-family: $National2Medium;
					font-size: 12px;
					font-weight: 500;
					height: 35px;
					text-align: center;
					line-height: 12px;
					padding: 5px 10px 5px 10px;
					width:92px;
					min-width: 100px;
					margin-top: 7px;
					margin-bottom: 6.74px;
				}
			}

			.head-track-order-Txt {
				padding-right: 15px;

				span {
					font-family: $National2Regular;
					font-style: normal;
					font-size: 14px;
					letter-spacing: -0.1px;
					color: #fff;
					text-align: center;
					line-height: 24px;
					font-weight: 400;
					padding: 5px 0px !important;
					float: right;
				}
				b.your-order {
					font-family: $National2Regular;
					font-weight: 700;
				}
			}
		}

		@media #{$breakpoint-below-tablet} {
			height: 41px !important;
			padding: 0px 3% !important;

			.headBottomParent {
				margin: 0.5% 0px;
				padding: 0px !important;

				.head-track-order-Txt {
					padding: 0px 10px 0px 20px !important;
					width: inherit;

					span {
						text-align: left !important;
						float: left;
						letter-spacing: 0px;
						font-size: 12px;
					}
				}

				.header-track-order-btn {
					padding: 0px !important;
					align-self: center;

					button {
						float: right;
						height: 30px;
						padding: 3px 12px;
						margin-right:20px;
						font-size: 10px;
					}
				}
			}
		}
		@media #{$mobile-only}{
			.headBottomParent {
				.head-track-order-Txt {
					padding: 0px 10px 0px 12px !important;
					width: inherit;
				}
		}
	}
	}
}
