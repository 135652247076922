.select-content-disposition {
	&.au {
		text-align: $center;
		padding-bottom: 30px;

		@media #{$breakpoint-below-tablet} {
			padding: 0% 3% 1%;
		}

		& .stripes {
			margin: -3px auto 0;
			height: 30px;
		}

		& .disposition-headline {
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 24px;
			text-align: $center;
			text-transform: uppercase;
			color: $Brand-Black;
			margin-top: 24px;
		}

		& .disposition-info-text {
			margin-top: 1%;
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: -0.1px;
			color: $Brand-Black;

			@media #{$breakpoint-below-tablet} {
				margin-top: 2%;
			}
		}

		& .disposition-btns {
			background: $Light-Gray;
			display: $d-inline-flex;
			border-radius: 200px;
			margin-top: 20px;
			padding: 2px;

			@media #{$mobile-only} {
				margin-top: 19.71px;
			}

			& .button {
				padding: 12px 47px;
				font-family: $National2Condensed;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 1px;
				color: $Dark-Gray;

				@media #{$breakpoint-below-tablet} {
					padding: 10px 24px;
				}

				@media #{$breakpoint-below-include-iphoneX} {
					padding: 10px 27px;
				}

				@media #{$breakpoint-iphone-ios15} {
					padding: 10px 0 !important;
					width: 7rem;
				}

				@media #{$breakpoint-below-include-iphone5} {
					padding: 10px 20px;
				}

				@media #{$breakpoint-below-include-iphone5SE} {
					padding: 10px 15px;
				}

				&.active-disposition {
					background: $Brand-Red;
					color: $white;
				}
			}
		}
	}
}

.select-content-disposition-container {
	&.au {
		.content-location-img {
			height: 18px;
			width: 13.2px;
			margin: auto 9px auto 0px;
		
			@media #{$breakpoint-below-tablet} {
				height: 16px;
				width: 12px;
				margin: 4px 9px auto 0px;
			}
		}

		.headBottomTxt.content-text {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			text-transform: none;
			display: $d-inline-flex;
			letter-spacing: normal;
		
			@media #{$breakpoint-below-tablet} {
				width: 100%;
				display: block;
				margin-right: 5px;
			}
		
			.store-location {
				display: flex !important;
				& .location-icon {
					@media #{$breakpoint-above-tablet} {
						margin: 7px auto;
						height: fit-content;
					}
					& .content-location-img {
						@media #{$breakpoint-below-tablet} {
							height: 16px;
							width: 12px;
							margin: 3px 12px auto -10px;
						}
					}
				}
				& .content-stroe-wrap {
					height: fit-content;
					margin: auto 0;
				}
			}
		}
		    .button-contained{
					padding: 0px !important;
					min-width: 60px !important;
          font-size: 12px;
          font-weight: 500 ;
          line-height: 22px;
          text-align: center;
					font-style: normal;
					font-family: $National2Regular !important;
				}
		.content-clock-img {
			margin-left: 15px;
			height: 18px;
			width: 18px;
			margin-right: 5px;
		
			@media #{$breakpoint-below-tablet} {
				margin-left: -10px;
				height: 14px;
				width: 14px;
				margin-right: 9px;
			}
		}

		.content-time {
			display: flex;
			height: fit-content;
			margin: auto 0;
			& .clock-img {
				height: 18px;
				width: 18px;
				margin: auto 9px auto 15px;
		
				@media #{$breakpoint-below-tablet} {
					height: 14px;
					width: 14px;
					margin: auto 9px auto -1px;
				}
			}
		}

		.change-store-btn {
			padding: 2px 10px 3px;
			height: 32px;
			width: 71px;
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 22px;
			align-self: center;
		
			@media #{$breakpoint-above-tablet} {
				padding: 5px 15px;
			}
		
			@media #{$breakpoint-below-tablet} {
				height: 26px;
				width: 61px;
			}
		}

		.content-ribbon {
			& .headBottomParent.content-parent {
				flex-direction: row;
				.content-store-wrap {
					@media #{$breakpoint-above-tablet} {
						max-width: 600px;
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}
				@media #{$breakpoint-below-tablet} {
					width: inherit;
					display: $d-flex;
					padding: 1% 4%;
					text-align: left;
					margin: unset;
		
					& .content-store {
						font-family: $National2Regular;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 24px;
						text-align: left;
						letter-spacing: normal !important;
					}
		
					.address {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;
					}
				}
				.store-content {
					font-family: $National2Regular;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 32px;
					color: $white;
					display: $d-flex;
					flex-wrap: nowrap;
					align-content: space-between;
					margin: 2px 0px auto 0px;
					// width: fit-content;
					@media #{$breakpoint-below-tablet} {
						margin: 1px 11px auto 0px;
					}
					.disposition-type {
						margin-right: 1ch;
					}
					.store-address {
						line-height: 24px;
						font-family: $National2Medium;
						& .store-frmText {
							font-family: $National2Regular;
						}
						@media #{$breakpoint-below-tablet} {
							max-width: 235px;
							line-height: 24px;
							overflow: hidden;
							font-weight: 400;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
						}
					}
				}
			}
		}
	}
}
