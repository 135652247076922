@mixin bullet-wrap() {
  display: inline-block;
  content: '';
  -webkit-border-radius: 0.375rem;
  border-radius: 0.375rem;
  height: 8px;
  width: 8px;
  margin-right: 0.3rem;
}
.findkfc-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .findkfc-stripes-section {
    top: 0;
    background-color: transparent;
    z-index: 111;

    @media #{$breakpoint-above-tablet} {
      padding-bottom: 24px;
    }
    @media #{$breakpoint-below-tablet} {
      padding-bottom: 16px;
    }
  }

  .findkfc-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .findkfc-header-title {
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      line-height: 100%;
      letter-spacing: 1px;
      text-transform: $uppercase;
      text-align: center;
      font-size: 48px;
      @media #{$mobile-only} {
        width: 312px;
        font-size: 36px;
      }
    }
    .findnearkfc-text {
      width: 516px;
      height: 24px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.1px;
      color: #202124;
      flex: none;
      flex-grow: 0;
      margin: 40px auto;
      @media #{$mobile-only} {
        width: 312px;
        height: 42px;
        font-size: 14px;
        text-align: center;
        margin: 24px auto 40px auto;
      }
    }
  }
  .findkfc-search-store-container {
    margin: 0 5%;
    width: calc(100% - 10%);
    @media #{$breakpoint-above-tablet} {
      width: 600px;
      margin: auto;
    }
    @media #{$mobile-only} {
      margin: 0 16px;
      width: calc(100% - 32px);
    }
  }

  .mapview {
    & .err-container {
      z-index: 8;
      width: calc(100% - 10%) !important;
      position: absolute;
      margin: 1% auto;
      padding-bottom: 2%;
      @media #{$breakpoint-above-tablet} {
        width: 600px !important;
      }
      @media #{$breakpoint-below-tablet} {
        margin: 1.5% auto;
      }
    }
  }
  .listview {
    & .err-container {
      margin: auto;
      z-index: 8;
      width: 100% !important;
      position: relative;
      padding-bottom: 2%;
    }
  }

  .findkfc-delivery-hide-container {
    & .delivery-module-form.search-store-global {
      .placesautocomplete-border {
        display: none;
      }
    }
    .store-no-card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      margin: auto;
      flex-grow: 0;
    }
  }

  .delivery-module-form.search-store-global {
    margin: 0;

    & .search-store-global-content {
      margin: 0;
    }
    .placesautocomplete-border {
      border-top: 1px solid $Light-Gray;
      margin: 40px 0 15px 0;
    }

    & .kfc-near-you-section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      padding: 0;
      & .kfc-count {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: $Brand-Black;
        flex: none;
        order: 0;
        flex-grow: 0;
        // height: 21px
      }

      & .list-map-link-section {
        margin-left: auto;
        // height: 21px;
        & .list-map-link {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          display: flex;
          align-items: center;
          text-align: right;
          text-decoration-line: underline;
          color: $Brand-Black;
          flex: none;
          order: 1;
          flex-grow: 0;

          & .map-img {
            margin-right: 5px;
          }
        }
      }
    }

    & .login-section {
      width: 100%;
      & .hide-location-link {
        display: none;
      }
      & .disable-location-link {
        cursor: not-allowed;
        opacity: 0.7;
        & a {
          pointer-events: none;
          text-decoration: none;
        }
      }
    }

    & .global-map {
      & .disable-location-icon {
        opacity: 0.7;
        cursor: not-allowed;
        & img {
          pointer-events: none;
        }
      }
    }

    & .search-delivery-address {
      margin-right: 0px;
    }

    & .delivery-address-section {
      & .input-delivery-address input {
        padding-left: 2.7rem;
        border: 1px solid #4d4d50;
      }
    }
    & .closed-store {
      padding: 11px 32px !important;
      border: 1px solid $Brand-Black;
    }

    & .inline-error {
      & .err-container {
        position: absolute;
        width: 69%;
      }
    }
    & .alert-location-popup {
      margin: 3% 0;
      position: relative;
      width: 100%;
      top: 0;
      height: unset;
      padding: 5vh 0;

      & .location-alert-icon {
        margin-top: 0;
      }
    }
    & .location-error-message {
      padding: 0 15%;
      text-align: center;
    }

    & .unit-number-container {
      padding: 5% 0 0;
      height: auto;

      & .icon-with-address {
        padding: 0 15%;
      }
    }
    & .unit-number-input-div {
      position: relative;
      margin: 30px 0 0;
      width: 80%;

      & input {
        width: 100%;
        display: inline-block;
        padding: 0 0 5px 0;
        background-color: transparent;
        border: none;
        border-bottom: solid 1.5px $Brand-Black;
        outline: none;
        z-index: 20;
        font-weight: 500;
        font-family: $National2Regular;
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Brand-Black;
      }

      & label {
        display: inline-block;
        transition: all 0.2s ease;
        z-index: -1;
        position: relative;
        top: -30px;
        font-weight: 400;
        font-family: $National2Regular;
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Brand-Black;
      }
      & input[type='text']:focus ~ label,
      input[type='text']:valid ~ label {
        top: -50px;
        font-size: 12px;
        line-height: 22px;
        color: $Dark-Gray;
      }
    }

    @include inline-error-mixin;
  }

  .delivery-module-form {
    margin: 0;
    .search-store-global-content {
      margin: 0;
      & .login-section {
        width: 100%;
        & .hide-location-link {
          display: none;
        }
        & .disable-location-link {
          cursor: not-allowed;
          opacity: 0.7;
          & a {
            pointer-events: none;
            text-decoration: none;
          }
        }
        & .delivery-member-signin {
          width: 167px;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.1px;
          color: #202124;
          flex: none;
          order: 0;
          flex-grow: 0;
        }
        & .delivery-location-link {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          display: flex;
          align-items: center;
          text-align: right;
          text-decoration-line: underline;
          color: #202124;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }
    }
  }
  .store-dynamic-card-container {
    display: flex;
    flex-direction: column;
    background: #f8f7f5;
    position: relative;
    width: 100%;
    gap: 16px;
    padding: 48px 0px;
    align-items: center;

    @media #{$mobile-only} {
      padding: 48px 16px;
    }
  }
  .store-card-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 32px;
    transition: 0.4s all;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 8px;
    background: #ffffff;
    flex: none;
    flex-grow: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);
    margin: 0 5%;
    width: calc(100% - 10%);
    @media #{$breakpoint-above-tablet} {
      width: 600px;
      margin: auto;
    }
    @media #{$mobile-only} {
      padding: 24px;
      width: 100%;
    }

    & .store-card-storename-dist-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      & .store-card-storename-dist-section {
        display: flex;
        justify-content: center;
        align-items: center;
        & .store-card-storename {
          display: inline;
          &.store-card-name {
            display: flex;
            align-items: flex-end;
            font-family: $National2Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            z-index: 1;
            color: $Brand-Black;
            @media #{$breakpoint-below-tablet} {
              max-width: 260px;
              overflow: hidden;
              white-space: nowrap;
              margin: auto 0;
              text-overflow: ellipsis;
              @media (orientation: landscape) {
                max-width: 540px;
              }
            }
            @media #{$mobile-only} {
              max-width: 240px;
            }
          }
        }
        & .store-distance {
          margin-left: 8px;
          margin-top: 0;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: $gray-lightbg;
          display: flex;
          align-items: flex-end;
          letter-spacing: -0.1px;
          z-index: 1;
          @media #{$breakpoint-below-tablet} {
            font-size: 12px;
          }
        }
      }
    }

    & .store-card-content-container {
      margin: 0;
      padding-top: 0;
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;

      & .store-card-ordertypes {
        display: inline-flex;
        flex-wrap: wrap;
        grid-gap: 1%;
        gap: 1%;
        width: 100%;
        margin-bottom: 8px;

        & .store-card-order-option {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: $gray-lightbg;
          text-transform: capitalize;
          margin-right: 9px;
          @media #{$breakpoint-below-tablet} {
            font-size: 12px;
          }

          & .tick-image {
            height: 10px;
            width: 12px;
            margin-right: 5px;
          }
        }
      }

      & .store-card-store-address {
        width: 100%;

        & .store-address {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: $gray-lightbg;
          margin-right: 8px;
          letter-spacing: -0.1px;
          @media #{$breakpoint-below-tablet} {
            font-size: 14px;
          }
        }

        & .map-link {
          color: $Brand-Black !important;
          font-weight: 700;

          & .card-map-link {
            color: $Brand-Black !important;
            font-weight: 500 !important;
            font-family: $National2Medium;
            font-size: 14px !important;
            line-height: 24px !important;
            text-decoration: underline;
            -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
          }
        }
      }

      & .kfcdirectionLink {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        display: inline-block;
        align-items: center;
        text-align: right;
        color: #202124;
        @media #{$breakpoint-above-destop-large-desktop} {
          cursor: pointer;
        }
      }
      & .kfcdirection {
        font-family: $National2Regular;
        font-style: normal;
        // font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-decoration: none;
        font-weight: bold;
        color: #202124;
        margin-top: 16px;
        margin-bottom: 24px;
        span {
          margin-left: 8px;
        }
        @media #{$mobile-only} {
          margin-bottom: 16px;
        }
      }
     & .kfcmoreInfo {
        padding: 0 20px 0 0;
        min-width: auto;
        span {
          margin-left: 8px;
        }
    }

      & .mt15 {
        margin-top: 15px;
      }

      & .contact-icon {
        width: 19px;
        height: 19px;
        position: relative;
        left: -2px;
      }

      & .contact-info {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #202124;
        position: relative;
        top: -2px;
      }

      .open-text:before {
        background: #008300;
        @include bullet-wrap;
      }
      .closed-text:before {
        background: #ff2e00;
        @include bullet-wrap;
      }
      .until-text:before {
        content: '\2022';
      }
      .special-hrs {
        color: green !important;
      }
      .hours-view {
        width: 100%;
        .dispotion-hours-text {
          font-family: $National2Medium;
          font-style: normal;
          font-size: 14px;
          line-height: 24px;
          color: #202124;
          margin: 12px 0 7px 0;
        }
        .hours-text {
          width: 100%;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 25px;
          display: flex;
          align-items: center;
          letter-spacing: -0.1px;
          color: #000000;
        }
        .day-details {
          width: 50%;
        }
        .time-details {
          width: 50%;
          text-align: right;
        }
      }

      & .hoursHidden {
        display: none;
      }

      & .store-timing-msg {
        font-family: $National2Medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        display: flex;
        margin-top: 15px;
        @media #{$mobile-only} {
          font-size: 14px;
        }
        &.hoursHiddenArrow .caretIcon img {
          transform: rotate(180deg);
        }

        &.info-timingClosed,
        & .closed-text {
          color: $Red-Orange !important;
          font-family: $National2Medium;
          font-weight: 500 !important;

          & ul {
            display: flex;
            font-size: 14px;
            margin-bottom: 0;
            padding-left: 24px;

            & :first-child {
              list-style: none;
              position: relative;
              right: 12px;

              & .closed-text {
                text-transform: capitalize;
              }
            }
          }
          & .list-circle {
            display: list-item;
            color: #ff2e00;
            margin-left: 23px;
            left: 19px;
          }
        }
      }

      & .facilities {
        width: 100%;
        margin: 0 0 16px 0;

        & .facility-img {
          margin-bottom: 0;
          margin-right: 1%;
          width: 32px;
          height: 32px;
        }
      }
    }

    & .search-store-card-button {
      display: flex;
      width: 100%;
      @media #{$mobile-only} {
        display: block;
      }
      & .favorite-img {
        cursor: pointer;
        padding-right: 16.8px;
      }
      & .search-store-card-btn {
        width: 244px;
      }
      & .redButton {
        margin-left: 5px;
      }
      & button.button-contained {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        border-radius: 30px;
        margin-right: 15px;
        width: 160px;

        min-width: unset;
        @media #{$breakpoint-below-tablet} {
          width: 223px;
        }
        @media #{$mobile-only} {
          width: 100%;
          padding: 0 1.5rem;
        }
      }
      & .button-contained[data-type='primary'] {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: right;
        color: #ffffff;
      }

      .button-contained[data-type='tertiary'] {
        font-family: $National2Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: right;
        color: #202124;
        border: 1px solid #202124;
      }
    }
  }

  .global-map {
    @media #{$breakpoint-below-tablet} {
      display: flex;
      flex-direction: column;
    }
    .arrows {
      @media #{$mobile-only} {
        top: 38%;
      }
      @media #{$breakpoint-between-mob-tablet} {
        top: 44%;
        display: flex;
      }
      @media #{$breakpoint-between-ipad-tablet} {
        display: none;
      }
      @media #{$breakpoint-above-tablet} {
        display: none;
      }
    }

    .store-dynamic-mapcard-container {
      display: flex;
      flex-direction: column;
      @media #{$breakpoint-between-mob-ipad} {
        width: 100%;
        background: #f8f7f5;
        position: relative;
        flex: none;
        order: 5;
        flex-grow: 0;
        padding-top: 56px;
        // gap: 16px;
      }
      .store-card-container {
        display: flex;
        flex-direction: column;
        transition: 0.4s all;
        border: 1px solid #e3e3e3;
        box-sizing: border-box;
        border-radius: 8px;
        background: #ffffff;
        flex: none;
        flex-grow: 0;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);

        &.map-card {
          padding: 32px;
          z-index: 8;
          @media #{$breakpoint-above-tablet} {
            max-width: unset;
            width: 424px;
            margin: 87px 0 0 48px;
            position: absolute;
          }

          @media #{$breakpoint-below-tablet} {
            max-width: unset;
            width: calc(100% - 32px);
            box-sizing: border-box;
            bottom: unset;
            margin: 0 16px;
            padding: 24px 16px;
            position: relative;
          }
          @media #{$breakpoint-between-ipad-tablet} {
            max-width: unset;
            width: 42%;
            min-height: 235px;
            margin: 87px 0 0 16px;
            position: absolute;
          }

          & .store-index {
            font-family: $National2Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            display: flex;
            align-items: flex-end;
            color: #494949;
            flex: none;
            flex-grow: 0;
            z-index: 4;
            margin-bottom: 8px;
            @media #{$breakpoint-between-ipad-tablet} {
              display: none;
            }
            @media #{$breakpoint-above-tablet} {
              display: none;
            }
          }
          & .map-view {
            display: flex;
            & .store-card-name {
              max-width: 240px;
              overflow: hidden;
              white-space: nowrap;
              margin: auto;
              text-overflow: ellipsis;
              @media #{$breakpoint-below-tablet} {
                max-width: 260px;
              }
              @media #{$mobile-only} {
                max-width: 245px;
              }
            }
            & .store-distance {
              margin: 8px;
            }
          }
        }
      }
    }
    & .use-location-icon {
      margin: auto;
      position: absolute;
      z-index: 8;
      padding-right: 1%;
      right: 0px;
      cursor: pointer;
      @media #{$breakpoint-above-tablet} {
        bottom: unset !important;
      }
      @media #{$breakpoint-below-tablet} {
        display: block;
        width: 100vw;
        padding-left: 2%;
      }
      @media #{$mobile-only} {
        top: 38% !important;
      }
      @media #{$breakpoint-between-mob-tablet} {
        top: 44%;
      }
      @media #{$breakpoint-between-ipad-tablet} {
        display: none;
        display: flex;
        justify-content: flex-end;
        bottom: unset !important;
        top: unset;
      }
    }
  }

  & .mapcard-container {
    .map-top-note {
      z-index: 7;
    }
    #google-map {
      width: 100%;
      height: 600px !important;
      flex-grow: 0;
      z-index: 5;
      bottom: unset;
    }
  }
}
